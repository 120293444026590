<template>
  <v-dialog v-model="active" width="400">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" :color="color">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <div>Date: {{ stringFormatDate(chapel.date) }}</div>
        <div v-if="chapel.chapel">Speaker: {{ chapel.chapel.speaker || '--No Speaker Specified --' }}</div>
        <div v-if="chapel.chapel">Title: {{ chapel.chapel.title || '-- No Title Specified --' }}</div>
        <div v-if="chapel.chapel && chapel.chapel.series">Series: {{ chapel.chapel.series }}</div>
        <p style="margin:1em 0">Please enter the reason or explanation below.</p>
        <v-textarea v-model="reason" label="Reason" outlined hide-details></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Cancel</v-btn>
        <v-btn text color="primary" @click="save" :disabled="reason===''">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { stringFormatDate } from '@/helpers/formatters'
import { ref, computed } from '@vue/composition-api'

export default {
  props: {
    action: {
      type: String,
      required: true
    },
    chapel: {
      type: Object,
      required: true
    },
    stuid: {
      type: String,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const active = ref(false)
    const reason = ref('')
    const icon = computed(() => {
      if (props.action === 'add') return 'fal fa-plus'
      else if (props.action === 'remove') return 'fal fa-minus'
      return 'fal fa-question'
    })
    const color = computed(() => {
      if (props.action === 'add') return 'success'
      else if (props.action === 'remove') return 'error'
      return 'bad action'
    })
    const title = computed(() => {
      if (props.action === 'add') return 'Add Credit'
      else if (props.action === 'remove') return 'Remove Credit'
      return 'bad action'
    })

    const studentService = root.$feathers.service('chapel/student')
    async function save () {
      const $patch = {}
      let type = ''
      let credit = 0
      if (props.action === 'add') {
        type = 'Exemption'
        credit = 'creditValues' in props.chapel ? props.chapel.creditValues.Exemption : 1
      } else if (props.action === 'remove') {
        type = 'Removed'
        credit = 'creditValues' in props.chapel ? props.chapel.creditValues.Removed : 0
      }
      if ('index' in props.chapel && props.chapel.index != null) {
        $patch['credits.' + props.chapel.index + '.type'] = type
        $patch['credits.' + props.chapel.index + '.credit'] = credit
        $patch['credits.' + props.chapel.index + '.reason'] = reason.value
      } else {
        // Add the credit to the student's credit list
        $patch.$push = { credits: { credit, chapel: props.chapel._id, date: new Date(), type, reason: reason.value } }
      }
      console.log($patch)
      await studentService.patch(props.stuid, $patch)
      active.value = false
      emit('updated')
    }

    return {
      active,
      reason,
      icon,
      color,
      title,
      stringFormatDate,
      save
    }
  }
}
</script>
